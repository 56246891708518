import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="row py-5">
      <div className="col-lg-5 offset-lg-1">
        <p>{`Before`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3f52ba8be3ad0efc541aaf68766b2c8e/bcec7/rdc-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGvJ6JNKW1c5cCYP//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMREgQyQf/aAAgBAQABBQK25ha1jzgMWobOfauuimaLAAJ//8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAEDAQE/ATE//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAIhAAAQIDCQAAAAAAAAAAAAAAAAECITFRAxARIkFhcYKS/9oACAEBAAY/ArRuadSCv9KYuVVjqLzQ7UukS3IH/8QAHRAAAwACAgMAAAAAAAAAAAAAAAERITFBcVGRsf/aAAgBAQABPyHretNhCX1BSgvaidtQtMcgk1mXxB7HKma+XsLYkR//2gAMAwEAAgADAAAAEKsXQP/EABgRAAMBAQAAAAAAAAAAAAAAAAABERAx/9oACAEDAQE/ELYfSvP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCkP//EABwQAQACAwEBAQAAAAAAAAAAAAERMQAhQaFR0f/aAAgBAQABPxCVFwKo0FYtpiaf0YnhGlFGuuAcJLadd77zJm7DsFUXfvmDGQTRLr44wsyhbbKcBAMjG23cseJjP//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/3f52ba8be3ad0efc541aaf68766b2c8e/4b190/rdc-04.jpg",
                "srcSet": ["/static/3f52ba8be3ad0efc541aaf68766b2c8e/e07e9/rdc-04.jpg 200w", "/static/3f52ba8be3ad0efc541aaf68766b2c8e/066f9/rdc-04.jpg 400w", "/static/3f52ba8be3ad0efc541aaf68766b2c8e/4b190/rdc-04.jpg 800w", "/static/3f52ba8be3ad0efc541aaf68766b2c8e/bcec7/rdc-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-5 offset-lg-1">
        <p>{`After`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f8ecc79aca56b6d988a0c7028490fe17/bcec7/rdc-07.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEEAP/aAAwDAQACEAMQAAABm1ppKuA2M19IbjBP/8QAHBAAAQQDAQAAAAAAAAAAAAAAAgMEERIAASEj/9oACAEBAAEFAgGRph8JuPlQYcahZuUJXxfq3//EABoRAAICAwAAAAAAAAAAAAAAAAABAiEDEBH/2gAIAQMBAT8BjkSSolbO6//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAQEAAgMAAAAAAAAAAAAAAAABMZEhQaH/2gAIAQEABj8CY8VM7d7VOGIr/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARITGxQVGh/9oACAEBAAE/IczXyISApQV/K9Qhtf1AFNY5FHRq45X2js61yf/aAAwDAQACAAMAAAAQDxi8/8QAGREBAAIDAAAAAAAAAAAAAAAAAQAQEUGx/9oACAEDAQE/EBJ4iysEbr//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCkP//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQWFxgdH/2gAIAQEAAT8Qr6NXd8hgBhdG2CDVD4+JZ6ybYbac+ohGganEPXTW3RNwJRbvvpliSL7/AFLaDhRzif/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/f8ecc79aca56b6d988a0c7028490fe17/4b190/rdc-07.jpg",
                "srcSet": ["/static/f8ecc79aca56b6d988a0c7028490fe17/e07e9/rdc-07.jpg 200w", "/static/f8ecc79aca56b6d988a0c7028490fe17/066f9/rdc-07.jpg 400w", "/static/f8ecc79aca56b6d988a0c7028490fe17/4b190/rdc-07.jpg 800w", "/static/f8ecc79aca56b6d988a0c7028490fe17/bcec7/rdc-07.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cc5ff8822905f30e81ab637e1c117c97/bcec7/rdc-08.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABvn2RFcPB0BsB/8QAGxABAAICAwAAAAAAAAAAAAAAAQADAhIQEyH/2gAIAQEAAQUCKvdOC3DsbQxUlhpK1cZ//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/ASN//8QAHRABAAEDBQAAAAAAAAAAAAAAARAAAiEREkFhcf/aAAgBAQAGPwJ7nYOaW7GkIcNe2x//xAAaEAEAAwEBAQAAAAAAAAAAAAABACExEUFx/9oACAEBAAE/IeCeqDLmToSA0RT7KdeQxRxfZzKrrMowqf/aAAwDAQACAAMAAAAQu8B+/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxClFn//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EDCa/8QAHRABAQEAAgMBAQAAAAAAAAAAAREAIVExQXFhsf/aAAgBAQABPxCVq1M9cSZeP5giI35nkUcHXi+c/QOjXRMlSGhyvoV9CFOcBGO02sefugQoID8N/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/cc5ff8822905f30e81ab637e1c117c97/4b190/rdc-08.jpg",
                "srcSet": ["/static/cc5ff8822905f30e81ab637e1c117c97/e07e9/rdc-08.jpg 200w", "/static/cc5ff8822905f30e81ab637e1c117c97/066f9/rdc-08.jpg 400w", "/static/cc5ff8822905f30e81ab637e1c117c97/4b190/rdc-08.jpg 800w", "/static/cc5ff8822905f30e81ab637e1c117c97/bcec7/rdc-08.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/92ed2f4236a6fede13625d121c8ef4a2/bcec7/rdc-12.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQFAwb/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwH/2gAMAwEAAhADEAAAAelvntEvQQp3rGxqDH//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIEEQMT/9oACAEBAAEFAlckKwnH2bGQ3y0dblE4NmI//8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECEBESQf/aAAgBAwEBPwGCyzRHK//EABkRAAEFAAAAAAAAAAAAAAAAAAABAhARQv/aAAgBAgEBPwFxamo//8QAHBAAAQQDAQAAAAAAAAAAAAAAAQACECERMTJx/9oACAEBAAY/ArDUDhUIb5HMaX//xAAdEAACAgIDAQAAAAAAAAAAAAAAAREhMUFRYXGR/9oACAEBAAE/Id0GYFqYkVykfpGBVBNFtSMlE/XI4ndcaJNPh//aAAwDAQACAAMAAAAQbCA//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARUf/aAAgBAwEBPxAcGOcXW//EABcRAQEBAQAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QWHJaqn//xAAeEAEBAAICAgMAAAAAAAAAAAABEQAhQVExYXGRwf/aAAgBAQABPxC8CO4n7nSxjTAIXtXCDRewmXgaNo++cQJORUyJKBa1fbhmuLDAceMUVV+Gf//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/92ed2f4236a6fede13625d121c8ef4a2/4b190/rdc-12.jpg",
                "srcSet": ["/static/92ed2f4236a6fede13625d121c8ef4a2/e07e9/rdc-12.jpg 200w", "/static/92ed2f4236a6fede13625d121c8ef4a2/066f9/rdc-12.jpg 400w", "/static/92ed2f4236a6fede13625d121c8ef4a2/4b190/rdc-12.jpg 800w", "/static/92ed2f4236a6fede13625d121c8ef4a2/bcec7/rdc-12.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/849c3b393b7db3e174f87ddf503629a1/bcec7/rdc-09.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEAQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAHuWxMxvZjKXcxacYB//8QAHBAAAgIDAQEAAAAAAAAAAAAAAQIAAxEhIgQT/9oACAEBAAEFAijJUlorf7Ezqeivtkw1e0fcY5b/xAAWEQEBAQAAAAAAAAAAAAAAAAAQARH/2gAIAQMBAT8B0h//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/AYQef//EAB0QAAMAAQUBAAAAAAAAAAAAAAABEXECECFRUsH/2gAIAQEABj8CnwteCp7aV6GkVmORvs//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGh/9oACAEBAAE/IcI01qHJC+oqBjAxv1jPu1HY2HyWNN9mXdCHxio90z//2gAMAwEAAgADAAAAEO/3f//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/EHHS3YNhWf/EABgRAQEAAwAAAAAAAAAAAAAAAAABESFx/9oACAECAQE/EK9I3WI//8QAIBABAQACAQMFAAAAAAAAAAAAAREAITFBUYFhkbHR8P/aAAgBAQABPxCbNADY7s53k/plInUfPGAxQo2ZSjHpAwzKIWtCP04hNUnwxJB3vdgUVEPCgal8++Twvq4v4z//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/849c3b393b7db3e174f87ddf503629a1/4b190/rdc-09.jpg",
                "srcSet": ["/static/849c3b393b7db3e174f87ddf503629a1/e07e9/rdc-09.jpg 200w", "/static/849c3b393b7db3e174f87ddf503629a1/066f9/rdc-09.jpg 400w", "/static/849c3b393b7db3e174f87ddf503629a1/4b190/rdc-09.jpg 800w", "/static/849c3b393b7db3e174f87ddf503629a1/bcec7/rdc-09.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e673ef7d4c113fd95e64f42afbafd659/bcec7/rdc-03.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAYAQEAAwEAAAAAAAAAAAAAAAAAAQIDBf/aAAwDAQACEAMQAAAB4c5nLo2WGNSaAf/EABkQAAIDAQAAAAAAAAAAAAAAAAEhAAISMf/aAAgBAQABBQK0FmyT1RCXOTtdn//EABgRAAMBAQAAAAAAAAAAAAAAAAABIVGB/9oACAEDAQE/AXTpolD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAADAAMBAAAAAAAAAAAAAAAAASEQETFx/9oACAEBAAY/AsRHSFejXfSJFP/EABsQAAMAAwEBAAAAAAAAAAAAAAABESExUWHR/9oACAEBAAE/IWmtjsWOx0QifyLOgmtKwZNh4pSpB9JFyb6f/9oADAMBAAIAAwAAABCzFwD/xAAYEQEBAQEBAAAAAAAAAAAAAAABABFxof/aAAgBAwEBPxBK3cC76LWAhS//xAAYEQEBAAMAAAAAAAAAAAAAAAAAASFRgf/aAAgBAgEBPxCYcaWv/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIVFBMYH/2gAIAQEAAT8Q1oOmII+3+R2Lk3OEbL8G2t5KEWKUvCW0AsG7p9ZkTiFIW4Xg2AC4lrqf/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/e673ef7d4c113fd95e64f42afbafd659/4b190/rdc-03.jpg",
                "srcSet": ["/static/e673ef7d4c113fd95e64f42afbafd659/e07e9/rdc-03.jpg 200w", "/static/e673ef7d4c113fd95e64f42afbafd659/066f9/rdc-03.jpg 400w", "/static/e673ef7d4c113fd95e64f42afbafd659/4b190/rdc-03.jpg 800w", "/static/e673ef7d4c113fd95e64f42afbafd659/bcec7/rdc-03.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cc7926c924d56ce3238c938beb94950d/bcec7/rdc-13.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAv/EABcBAAMBAAAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAYKYd8UsQHPOkF0A/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAxESIiH/2gAIAQEAAQUCR7FCmx41eCns2dVGxiHmNyf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAwEBPwFyNY6//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAQUBAAAAAAAAAAAAAAAAAAEQESExgf/aAAgBAQAGPwLTUg1orjoUf//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUZFx/9oACAEBAAE/IaAA+sNp7mpqPcus8jpY7DG7jloHqamJU//aAAwDAQACAAMAAAAQf+9C/8QAFxEBAQEBAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPxAgwl1P/8QAFxEBAAMAAAAAAAAAAAAAAAAAABARQf/aAAgBAgEBPxBbY//EABsQAQEAAwEBAQAAAAAAAAAAAAERACExQWFR/9oACAEBAAE/EDxg5PWN757GfDBEIEu28K/FS8uEWh8UZ+ZFnBhoxMI7kS4L0zdL9xzIQMD3P//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/cc7926c924d56ce3238c938beb94950d/4b190/rdc-13.jpg",
                "srcSet": ["/static/cc7926c924d56ce3238c938beb94950d/e07e9/rdc-13.jpg 200w", "/static/cc7926c924d56ce3238c938beb94950d/066f9/rdc-13.jpg 400w", "/static/cc7926c924d56ce3238c938beb94950d/4b190/rdc-13.jpg 800w", "/static/cc7926c924d56ce3238c938beb94950d/bcec7/rdc-13.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/da5a18f81f18736da0ba2e8c2907abf2/bcec7/rdc-11.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAYtOIXOuNGgs4Rb/AP/EAB0QAAICAQUAAAAAAAAAAAAAAAECAAMEERITITP/2gAIAQEAAQUCS4Qu2ruSy1IrZPbjH3Cn2vnIwH//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPwEp/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQIBAT8BIf/EAB0QAAICAQUAAAAAAAAAAAAAAAABAhEhEDJBUaH/2gAIAQEABj8CUVQ+KN/os5RGPZakRRF6f//EABoQAAMBAQEBAAAAAAAAAAAAAAABESFBUTH/2gAIAQEAAT8hUV/gtxdcL5TgpZHitoIUvGvClQ5uGvSiQR/dP//aAAwDAQACAAMAAAAQVye8/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxClHj//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ENhUIRH/xAAeEAEBAAICAgMAAAAAAAAAAAABEQAhMUFRYYGx0f/aAAgBAQABPxBrF0BYrl5VC+A4+86NQZJr1ldElb3TRTXHvIUG73pTXwmC4Aot33iEWwpokecFpQ6pqZFIitd1/M//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/da5a18f81f18736da0ba2e8c2907abf2/4b190/rdc-11.jpg",
                "srcSet": ["/static/da5a18f81f18736da0ba2e8c2907abf2/e07e9/rdc-11.jpg 200w", "/static/da5a18f81f18736da0ba2e8c2907abf2/066f9/rdc-11.jpg 400w", "/static/da5a18f81f18736da0ba2e8c2907abf2/4b190/rdc-11.jpg 800w", "/static/da5a18f81f18736da0ba2e8c2907abf2/bcec7/rdc-11.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/00d58b02cbab4879508d1427c631a97a/bcec7/rdc-02.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABax86lqIMwgB//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEgMEERMi/9oACAEBAAEFAqLRK160myRxizMinaeFQ2NPIn//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAgEAABAwMFAQAAAAAAAAAAAAABAAIRITFBAxASQlGB/9oACAEBAAY/ApqgarK03MN09w9sqcR8TJwxGOwM7f/EAB0QAQACAQUBAAAAAAAAAAAAAAEAESExQVFxkWH/2gAIAQEAAT8hrRr2wchNoq6Tf5VmnuFOOFrhuOcPhBrsU9YaWgXi5c//2gAMAwEAAgADAAAAENPIv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABgRAAIDAAAAAAAAAAAAAAAAAAARARAh/9oACAECAQE/EFgpv//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExUYGRsf/aAAgBAQABPxC8UDkdeXLpaS0TLxs9mWgBA0wRn3DF4glBNjrcPmVIydn7gUu4DItMFM7YeZB8TFLVVz//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/00d58b02cbab4879508d1427c631a97a/4b190/rdc-02.jpg",
                "srcSet": ["/static/00d58b02cbab4879508d1427c631a97a/e07e9/rdc-02.jpg 200w", "/static/00d58b02cbab4879508d1427c631a97a/066f9/rdc-02.jpg 400w", "/static/00d58b02cbab4879508d1427c631a97a/4b190/rdc-02.jpg 800w", "/static/00d58b02cbab4879508d1427c631a97a/bcec7/rdc-02.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/48d3c576e52a0dc9c7319d0bf60304d1/bcec7/rdc-10.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAc0tN9cZYcTuJzAP/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEQASEyH/2gAIAQEAAQUCK6osdiqxYg8QjqA+no3MSNyOf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB4QAAIBAwUAAAAAAAAAAAAAAAABEQIhURASQWGB/9oACAEBAAY/Aqd3OCcuEy4mzuJJHcpXmn//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVFB/9oACAEBAAE/IQZs2PcoeiQUk6iE3WwLjjL5N6q1lvCczzIYz7GJ/9oADAMBAAIAAwAAABA7MMH/xAAXEQEBAQEAAAAAAAAAAAAAAAABABBB/9oACAEDAQE/EBJbuf/EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPxCEnIv/xAAcEAEBAAMBAQEBAAAAAAAAAAABEQAhMUGRUaH/2gAIAQEAAT8QH+6hozm8aKJJYB5TzeSIColyZQJJsOQ+YIRuHSepMFKEKBiAFymoRp/X7kuoiPqX9wiIDr3ef//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rdc",
                "title": "rdc",
                "src": "/static/48d3c576e52a0dc9c7319d0bf60304d1/4b190/rdc-10.jpg",
                "srcSet": ["/static/48d3c576e52a0dc9c7319d0bf60304d1/e07e9/rdc-10.jpg 200w", "/static/48d3c576e52a0dc9c7319d0bf60304d1/066f9/rdc-10.jpg 400w", "/static/48d3c576e52a0dc9c7319d0bf60304d1/4b190/rdc-10.jpg 800w", "/static/48d3c576e52a0dc9c7319d0bf60304d1/bcec7/rdc-10.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      